import React from "react"

import LayoutLanding from "../components/layout-landing"
import Info from "../components/info"
import SEO from "../components/seo"
import styles from "./r.module.scss"

import API, { graphqlOperation } from '@aws-amplify/api';
import config from '../aws-exports';

class RegistrationPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { catalog: null, failedToLookupCode: false };

    var params = new URLSearchParams(this.props.location.search);

    var key = null;
    if (params) {
      key = params.get("c");
    } else {
      var logger = _LTracker || [];
      logger.push('Failed to get URLSearchParams object on reg page');

    }

    if (key) {
      API.configure(config);
      this.lookUpCatalogCode(key);
    } else {
      this.goToFrontPageInvalidKey();
    }

  }

  createState = (catalog, hasError = false, validationErrors = false, submittingOrder = false) => {
    return {
      catalog: catalog,
      hasError: hasError,
      validationErrors: validationErrors,
      submittingOrder: submittingOrder
    };
  }

  lookUpCatalogCode = (key) => {

    const query = `query GetCatalogByCode($code: String) {
      getCatalogByCode(code: $code) {
        name
        partner
        year
        week
        price
        quantity
        totalPrice
        totalDiscount
        eligibleName
        key
        productImage
      }
    }`;
    API.graphql(graphqlOperation(query, { code: key }))
      .then(result => {

        this.setState(prevState => (
          this.createState(result.data.getCatalogByCode)
        ));
        
        if (!this.state.catalog) this.goToFrontPageInvalidKey();
      })
      .catch(e => {

        console.error('ERROR LOOKING UP CODE');
        var logger = _LTracker || [];
        logger.push("Failed look up code:" + key);
        if(e) {
          console.error(e.message);
          logger.push(e);
          logger.push(e.message);
        }

        this.goToFrontPageError();
      });

  }

  onOrder = (e => {
    var form = e.target;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
      form.classList.add('was-validated');
      return;
    }

    this.setState(prevState => (
      this.createState(prevState.catalog, false, false, true)
    ));

    const order = this.getOrder(e.target);
    const query = `query RegisterOrder($order: RegisterOrder) {
      registerOrder(order: $order) {
        success
          confirmation {
            id
          }
          validationErrors {
            field
            code
            message
          }
      }
    }`;

    API.graphql(graphqlOperation(query, { order: order }))
      .then(result => {
        
        if (result.data.registerOrder.success) {
          if (typeof window !== 'undefined') { // window is not available at build time
            window.location.href = "/confirmation/?key=" + this.state.catalog.key + '&id=' + result.data.registerOrder.confirmation.id;
          }
        } else if (result.data.registerOrder.validationErrors.length > 0) {
          this.setState(prevState => (
            this.createState(prevState.catalog, false, true)
          ));
        } else {
          console.log('We should never go here......');
          this.setState(prevState => (
            this.createState(prevState.catalog, false, true)
          ));
        }

      })
      .catch(e => {
        var logger = _LTracker || [];
        logger.push("Failed to register order with email:" + order.email);

        if(e) {
          logger.push(e);
          logger.push(e.message);
        }
        this.setState(prevState => (
          this.createState(prevState.catalog, true, false)
        ));

      });


  });

  getOrder(form) {
    const order = {};
    const formData = new FormData(form);

    if (`entries` in formData) {

      for (let entry of formData.entries()) {
        order[entry[0]] = entry[1];
      }
    } else {
      // not all browsers supports formData.entries()
      console.log('Using backup way to get the order data');
      order.name = form.name.value;
      order.phone = form.phone.value;
      order.email = form.email.value;
      order.streetAddress = form.streetAddress.value;
      order.postalNumber = form.postalNumber.value;
      order.postalArea = form.postalArea.value;
    }

    order.quantity = this.state.catalog.quantity;
    order.key = this.state.catalog.key;
    return order;
  }

  goToFrontPageInvalidKey() {
    this.goToFrontPage('invalid-key');
  }

  goToFrontPageError() {
    this.goToFrontPage('error');
  }


  goToFrontPage(infoKey) {
    if (typeof window !== 'undefined') { // window is not available at build time
      window.location.href = "/?error=" + infoKey;
    }
  }

  render() {

    return (
      <LayoutLanding>
        <SEO title="Bestilling" />

        <div className="row">
          <div className="col">


            {this.state.validationErrors ?
              <div className="alert alert-danger" role="alert">Alle feltene utenom mobilnr er påkrevd.</div>
              : null
            }


            {this.state.hasError ?
              <div className="alert alert-danger" role="alert">Ooops noe gikk galt....</div>
              : null
            }

            {!this.state.catalog && !this.state.failedToLookupCode ?
              <div className="spinner-border" role="status">
                <span className="sr-only">Laster informasjon om leirskole dagboken din...</span>
              </div>
              : null
            }

            {this.state.catalog && !this.state.failedToLookupCode ?
              <form id="orderForm" onSubmit={this.onOrder} className="text-left" noValidate >
                <p className={`${styles.topInfoText}`}>Ta vare på leirskoleminnene! Vi har samlet bildene fra {this.state.catalog.eligibleName} sitt
                  leirskoleopphold på {this.state.catalog.partner} i uke {this.state.catalog.week} {this.state.catalog.year}, 
                  og laget en flott bok med bilder og tekster fra opplevelsen.
                  
                  {this.state.catalog.quantity > 1 ?
                    <span>
                      {' '}{this.state.catalog.quantity} bøker bestilles hjem til fritt gjennomsyn for totalt kr {this.state.catalog.totalPrice} inkl. porto.
                  </span>
                    :
                    <span> Når du bestiller får du den ferdige utgaven tilsendt til fritt gjennomsyn, deretter kan du bestemme deg om du vil ha den eller ikke.</span>
                  }

                </p>

                <div className={`form-group`}>
                  <input id="fullname" name="name" type="text" className={`form-control form-control-lg`} placeholder="Navn på bestiller" autoComplete="name" required />
                </div>
                <div className={`form-group`}>
                  <input name="phone" type="text" className={`form-control form-control-lg`} placeholder="Mobilnr" autoComplete="tel" />
                </div>
                <div className={`form-group`}>
                  <input name="email" type="email" className={`form-control form-control-lg`} placeholder="Epost" autoComplete="email" required />
                  <div className={`invalid-feedback ${styles.invalidFeedbackRegistration}`}>
                    Vennligst fyll inn en gyldig epost addresse. Den brukes kun til å sende bestillings bekreftelse og faktura på e-post.
                  </div>

                </div>
                <div className={`form-group`}>
                  <input name="streetAddress" type="text" className={`form-control form-control-lg`} placeholder="Gateaddresse" autoComplete="street-address" required />
                </div>
                <div className="form-row">
                  <div className="col-sm-3 mb-3">
                    <input name="postalNumber" type="text" className={`form-control form-control-lg`} placeholder="Postnr" autoComplete="postal-code" required />
                  </div>
                  <div className="col-sm-9 mb-3">
                    <input name="postalArea" type="text" className={`form-control form-control-lg`} placeholder="Poststed" autoComplete='address-level2' required />
                  </div>
                </div>
                <p>
                  Ved å bestille så godkjenner du våre <a target="_blank" href="/kjops-vilkaar/">kjøpsvilkår</a>.
                </p>
                <p>
                  Vi tar personvern på alvor se vår <a target="_blank" href="/privacy-policy/"> personvernerklæring</a>.
                </p>

                {this.state.submittingOrder ?
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Vi jobber med bestillingen din</span>
                  </div>

                  :
                  <div className={`form-group`}>
                    <button id="checkCode" className={`btn btn-primary btn-lg form-control-lg btn-block`} >Bestill leirskoledagboken</button>
                  </div>

                }

                <Info productPrice={this.state.catalog.totalPrice} productImageUrl={this.state.catalog.productImage}></Info>
                
              </form>
              :
              null
            }


          </div>
        </div>
      </LayoutLanding>)
  }

}
export default RegistrationPage
